






































































































import {Component, Vue} from 'vue-property-decorator';
import Logo from "@/components/Logo.vue";
import Banner from "@/components/Banner.vue";
import {fieldSort, RegistrationDate, RegistrationDOIType, RegistrationForm} from "@/models/RegistrationForm";
import {CustomFieldWithValueString, FieldWithValueBoolean, FieldWithValueString, UserData} from "@/models/UserData";
import {format as formatDate, Locale} from "date-fns";
import UserDataItem from "@/components/UserDataItem.vue";
import {Page} from "@/store/form";
import {salutationString} from "@/utils/utils";

@Component({
  components: {UserDataItem, Banner, Logo}
})
export default class Result extends Vue {

  get form(): RegistrationForm {
    return this.$store.getters.form;
  }

  get userData(): UserData {
    return this.$store.getters.userData;
  }

  get selectedDates(): Array<RegistrationDate> {
    if (this.form === undefined) return [];
    return this.form.regDates.filter(regDate => this.userData.dateIds.includes(regDate.id))
  }

  get locale(): Locale {
    return this.$store.getters.locale;
  }

  get key(): string | undefined {
    return this.$store.getters.key;
  }

  get page(): Page {
    return this.$store.getters.page;
  }

  get fieldsString(): Array<FieldWithValueString> {
    if (!this.form || !this.form.fields || !this.userData.values) return [];
    return this.form.fields
        .filter(field => field.type === 'TEXT_LINE')
        .sort(fieldSort)
        .map(field => {
          const fieldValue = this.userData.values.find(fieldValue => fieldValue.fieldId === field.id);
          return {field, value: fieldValue ? fieldValue.value : ""};
        });
  }
  get customFieldsString(): Array<CustomFieldWithValueString> {
    if (!this.form || !this.form.customFields || !this.userData.customValues) return [];
    return this.form.customFields
        .map(field => {
          const fieldValue = this.userData.customValues.find(fieldValue => fieldValue.fieldId === field.id);
          return {field, value: fieldValue ? fieldValue.value : ""};
        });
  }

  get fieldsBoolean(): Array<FieldWithValueBoolean> {
    if (!this.form || !this.form.fields || !this.userData.values) return [];
    return this.form.fields
        .filter(field => field.type === 'CHECKBOX')
        .sort(fieldSort)
        .map(field => {
          const fieldValue = this.userData.values.find(fieldValue => fieldValue.fieldId === field.id);
          return {field, value: fieldValue ? fieldValue.value === "true" : false};
        })
  }

  format(date: Date, format: string): string {
    return formatDate(date, format, {locale: this.locale});
  }

  edit(): void {
    if (this.key === undefined) return
    // Add key to current URL, this will reload the page
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('key', this.key);
    window.location.search = urlParams.toString();
  }

  get privacyLabel(): string {
    return this.$store.getters.privacy;
  }

  get isDoiConference(): boolean {
    if (this.userData.dateDoi) return false;
    if (!this.form || !this.form.doi) return false;
    return this.form.doi.type === "CONFERENCE";
  }

  salutationString(salutation: string): string {
    if (!this.form) return '-';
    return salutationString(salutation, this.form, false);
  }
}
